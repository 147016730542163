<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
        <template #activator="{ on }">
            <v-text-field v-model="date" v-bind="textFieldAttrs" :label="label" :placeholder="placeholder" :dense="dense" :hide-details="hideDetails" prepend-icon="calendar_month" readonly v-on="on"> </v-text-field>
        </template>
        <v-date-picker v-model="date" :type="type" :min="min" :allowed-dates="allowDates" no-title scrollable @input="$refs.menu.save(date)"></v-date-picker>
    </v-menu>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    components: {
        UIcon,
    },
    props: {
        value: { type: String, default: undefined },

        textFieldAttrs: { type: Object, default: () => ({}) },
        type: { type: String, default: "date" },
        label: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
        dense: { type: Boolean, default: false },
        hideDetails: { type: String, default: "auto" },
        limitDates: { type: Array, default: () => [] },
        min: { type: String, default: undefined }
    },
    data() {
        return {
            date: this.$props.value,
        };
    },
    methods: {
        allowDates(value) {
            return this.limitDates.indexOf(value) === -1;
        },
    },
    watch: {
        date() {
            this.$emit("input", this.date);
        },
        value(){
            this.date = this.$props.value;
        }
    },
};
</script>
