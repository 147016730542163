var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "font-size-14 grey--text text-right mb-12px mb-md-16px",
    style: _vm.$vuetify.breakpoint.smAndUp ? 'margin-top:calc((20px + var(--tit-wrap-padding-bottom-sm)) * -1)' : ''
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")]), _c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "신청자 명"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('text-field-primary', {
    attrs: {
      "dense": "",
      "placeholder": "신청자 명을 입력하세요."
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1)], 1), _c('template', {
    slot: "연락처"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('text-field-primary', {
    attrs: {
      "dense": "",
      "type": "number",
      "rules": _vm.phoneRules
    },
    model: {
      value: _vm.phone1,
      callback: function ($$v) {
        _vm.phone1 = $$v;
      },
      expression: "phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('text-field-primary', {
    attrs: {
      "dense": "",
      "type": "number",
      "rules": _vm.phoneRules
    },
    model: {
      value: _vm.phone2,
      callback: function ($$v) {
        _vm.phone2 = $$v;
      },
      expression: "phone2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('text-field-primary', {
    attrs: {
      "dense": "",
      "type": "number",
      "rules": _vm.phoneRules
    },
    model: {
      value: _vm.phone3,
      callback: function ($$v) {
        _vm.phone3 = $$v;
      },
      expression: "phone3"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('template', {
    slot: "날짜 선택"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-date-field', {
    attrs: {
      "limitDates": _vm.limitation.limitDates,
      "min": new Date().toISOString().slice(0, 10),
      "textFieldAttrs": Object.assign({}, _vm.attrs_input, {
        className: 'white w-md-170px'
      }),
      "hideDetails": "auto",
      "placeholder": "선택"
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.form.applyDate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "applyDate", $$v);
      },
      expression: "form.applyDate"
    }
  })], 1)], 1)], 1), _c('template', {
    slot: "시간 선택"
  }, [_c('v-radio-group', _vm._b({
    attrs: {
      "row": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.form.applyTime,
      callback: function ($$v) {
        _vm.$set(_vm.form, "applyTime", $$v);
      },
      expression: "form.applyTime"
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('radio-primary', {
    attrs: {
      "label": "오전(09:00~13:00)",
      "value": "오전(09:00~13:00)"
    }
  }), _c('radio-primary', {
    attrs: {
      "label": "오후(13:00~16:30)",
      "value": "오후(13:00~16:30)"
    }
  })], 1)], 1), _c('template', {
    slot: "장비 선택"
  }, [_c('txt-primary', {
    staticClass: "txt--sm primary--text mb-6px"
  }, [_vm._v("※날짜와 시간 선택시 예약가능한 장비를 확인하실 수 있습니다. 본봉은 1인당 1대만 예약가능합니다.")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('본봉13,오버록'),
      "label": "본봉13,오버록",
      "value": "본봉13,오버록"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('본봉14,오버록'),
      "label": "본봉14,오버록",
      "value": "본봉14,오버록"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('본봉15,오버록'),
      "label": "본봉15,오버록",
      "value": "본봉15,오버록"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('날날이'),
      "label": "날날이",
      "value": "날날이"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('캐드'),
      "label": "캐드",
      "value": "캐드"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('오버록'),
      "label": "오버록",
      "value": "오버록"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary-array', {
    attrs: {
      "disabled": _vm.limitEquipments.includes('마네킹'),
      "label": "마네킹",
      "value": "마네킹"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.form.equipments,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipments", $$v);
      },
      expression: "form.equipments"
    }
  })], 1)], 1)], 1)], 2), _c('div', {
    staticClass: "pt-30px pt-md-34px pt-lg-40px"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "params": {
        code: 'collect-use'
      }
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('btn-secondary2', {
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('btn-primary2', _vm._b({
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save', _vm.form);
      }
    }
  }, 'btn-primary2', {
    disabled: _vm.disabled
  }, false), [_vm._v("신청하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }