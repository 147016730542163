<template>
    <terms-dialog v-model="selected" v-bind="{ params }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template #activator="{ on, isValid }">
            <div v-on="on" class="cursor-pointer">
                <!-- <div class="pb-12px">
                    <v-checkbox color="primary" label="I fully agree to the terms and conditions." :value="isValid" hide-details readonly class="v-size--large" />
                </div> -->
                <card-primary cardInnerSize="card-inner--sm">
                    <v-row justify="center">
                        <v-col v-for="terms in termsList" :key="terms._id" cols="auto">
                            <v-checkbox v-if="terms.code == 'collect-use'" :value="selected.includes(terms._id)" hide-details color="primary" readonly class="my-8px my-md-12px">
                                <template #label>
                                    <b class="grey--text text--darken-4">
                                        {{ terms.subject }} 동의 <span class="pl-2px" :class="[terms.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "필수" : "선택" }})</span> <u class="ml-6px ml-md-10px">전문보기</u>
                                    </b>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </card-primary>
            </div>

        </template>
    </terms-dialog>
</template>

<script>
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
        CardPrimary,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        termsList: [],
        selected: [],
    }),
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style></style>
