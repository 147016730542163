var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-checkbox', _vm._b({
    class: _vm.checkboxSize,
    attrs: {
      "value": _vm.value,
      "inputValue": _vm.inputValue
    },
    on: {
      "change": _vm.updateModelValue
    },
    model: {
      value: _vm.modelValue,
      callback: function ($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common, _vm.$attrs), false), [_vm._l(_vm.$slots, function (_, slotName) {
    return _c('template', {
      slot: slotName
    }, [_vm._t(slotName)], 2);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }