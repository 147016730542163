<template>
    <v-form v-model="isValid">
        <div class="font-size-14 grey--text text-right mb-12px mb-md-16px" :style="$vuetify.breakpoint.smAndUp ? 'margin-top:calc((20px + var(--tit-wrap-padding-bottom-sm)) * -1)' : ''"><span class="red--text">*</span> 필수입력 항목입니다.</div>
        <vertical-form-table v-model="form" v-bind="{ items }">
            <template slot="신청자 명">
                <v-row align="center" class="row--xs">
                    <v-col cols="12" md="4">
                        <text-field-primary v-model="form.name" dense placeholder="신청자 명을 입력하세요." />
                    </v-col>
                </v-row>
            </template>
            <template slot="연락처">
                <v-row align="center" class="row--xs">
                    <v-col cols="12" md="4">
                        <v-row align="center" class="row--xxs">
                            <v-col cols="">
                                <text-field-primary v-model="phone1" dense type="number" :rules="phoneRules" />
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col cols="">
                                <text-field-primary v-model="phone2" dense type="number" :rules="phoneRules" />
                            </v-col>
                            <v-col cols="auto">-</v-col>
                            <v-col cols="">
                                <text-field-primary v-model="phone3" dense type="number" :rules="phoneRules" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
            <template slot="날짜 선택">
                <v-row align="center" class="row--xs">
                    <v-col cols="12" md="4">
                        <v-date-field v-model="form.applyDate" :limitDates="limitation.limitDates" :min="new Date().toISOString().slice(0, 10)" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택" @input="search" />
                    </v-col>
                </v-row>
            </template>
            <template slot="시간 선택">
                <!-- <txt-primary class="txt--sm primary--text mb-6px">※1일 최대 4시간 예약가능합니다.</txt-primary> -->
                <v-radio-group v-model="form.applyTime" v-bind="{ ...attrs_controls_common }" row @change="search">
                    <radio-primary label="오전(09:00~13:00)" value="오전(09:00~13:00)" />
                    <radio-primary label="오후(13:00~16:30)" value="오후(13:00~16:30)" />
                </v-radio-group>
            </template>
            <template slot="장비 선택">
                <txt-primary class="txt--sm primary--text mb-6px">※날짜와 시간 선택시 예약가능한 장비를 확인하실 수 있습니다. 본봉은 1인당 1대만 예약가능합니다.</txt-primary>
                <v-row align="center">
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('본봉13,오버록')" label="본봉13,오버록" value="본봉13,오버록" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('본봉14,오버록')" label="본봉14,오버록" value="본봉14,오버록" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('본봉15,오버록')" label="본봉15,오버록" value="본봉15,오버록" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('날날이')" label="날날이" value="날날이" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('캐드')" label="캐드" value="캐드" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('오버록')" label="오버록" value="오버록" @change="emit" />
                    </v-col>
                    <v-col cols="auto">
                        <checkbox-primary-array v-model="form.equipments" :disabled="limitEquipments.includes('마네킹')" label="마네킹" value="마네킹" @change="emit" />
                    </v-col>
                </v-row>
            </template>
        </vertical-form-table>

        <div class="pt-30px pt-md-34px pt-lg-40px">
            <terms-of-agreements-short v-model="form._terms" :params="{code: 'collect-use'}" @isValid="(isValid) => (isTermsValid = isValid)" />
        </div>

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--xs">
                <v-col cols="6" sm="auto">
                    <btn-secondary2 x-large class="w-100 min-w-sm-160px" @click="$router.go(-1)">취소</btn-secondary2>
                </v-col>
                <v-col cols="6" sm="auto">
                    <btn-primary2 v-bind="{ disabled }" x-large class="w-100 min-w-sm-160px" @click="$emit('save', form)">신청하기</btn-primary2>
                </v-col>
            </v-row>
        </div>
    </v-form>
</template>

<script>
import api from "@/api";
import { APPLICATION_STATUS } from "@/assets/variables";
import { attrs_input, attrs_controls_common } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";
// import ImagesInput from "@/components/console/dumb/images-input.vue";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import RadioPrimary from "@/components/publish/parents/forms/radio-primary.vue";
import CheckboxPrimary from "@/components/publish/parents/forms/checkbox-primary.vue";
import CheckboxPrimaryArray from "@/components/publish/parents/forms/checkbox-primary-array.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

const items = [
    {
        term: "신청자 명",
        required: true,
    },
    {
        term: "연락처",
        required: true,
    },
    {
        term: "날짜 선택",
        type: "date",
        required: true,
    },
    {
        term: "시간 선택",
        required: true,
    },
    {
        term: "장비 선택",
        required: true,
    },
    {
        term: "신청 사유",
        type: "textarea",
        key: "purpose",
        required: true,
        placeholder: "신청 사유를 입력하세요.",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,
        // ImagesInput,
        BtnPrimary2,
        BtnSecondary2,
        TextFieldPrimary,
        VDateField,
        RadioPrimary,
        CheckboxPrimary,
        CheckboxPrimaryArray,
        TxtPrimary,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            attrs_input,
            attrs_controls_common,

            work: null,
            form: this.$props.value,
            // form: {},
            limitation: {},

            items,
            isValid: false,
            isTermsValid: undefined,

            phone1: null,
            phone2: null,
            phone3: null,

            limitEquipments: [],
            phoneRules: [(v) => !!v || "연락처를 입력해주세요.", (v) => (v && v.length <= 4) || "4글자까지만 입력해주세요."],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let { limitation } = await api.v1.apply.limitations.get();
                this.limitation = limitation;
            } catch (error) {
                alert(error.message);
            }
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async search() {
            try {
                if (this.form.applyDate && this.form.applyTime) {
                    let { applications } = await api.v1.apply.applications.getEquipments({
                        params: {
                            applyDate: this.form.applyDate,
                            applyTime: this.form.applyTime,
                            status: APPLICATION_STATUS.REJECTED.value,
                        },
                    });
                    this.form.equipments = [];
                    this.limitEquipments = Array.from(new Set(applications.flatMap((app) => app.equipments)));
                }
            } catch (error) {
                console.error(error.message);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        emit() {
            this.form = { ...this.form };
        },
    },
    computed: {
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },

        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    watch: {
        phone() {
            this.form = { ...this.form, phone: this.phone };
        },
    },
};
</script>
