<template>
    <v-dialog v-model="shows" v-bind="{ ...$attrs }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, userTypeMatches }" />
        </template>

        <v-card class="rounded-0">
            <v-toolbar class="v-toolbar--border border-light">
                <v-toolbar-title><slot name="tit" /></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="v-size--xx-large transparent rounded-0" @click="close"><u-icon>close</u-icon></v-btn>
            </v-toolbar>
            <div class="pa-16px">
                <slot />
            </div>
            <v-card-actions v-if="$slots['actions']" class="px-12px px-md-20px border-light">
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {
        value: { type: Boolean, default: false }, // shows
        userType: { type: [Array, String], default: null },
        needsLogin: { type: Boolean, default: false },
    },
    components: {
        UIcon,
        Tit,
    },
    data() {
        return {
            shows: false,
        };
    },
    computed: {
        doesNotMatch() {
            return this.shows != this.value;
        },
        storeUserType() {
            return this.$store.state.payload?.userType;
        },
        storeUserTypeText() {
            return USER_TYPES[this.storeUserType]?.text;
        },
        userHasLoggedIn() {
            return this.$store.state.payload?._user;
        },
        userTypeMatches() {
            if (!this.userType) return true;
            if (typeof this.userType == "string") return this.userType == this.storeUserType;
            if (Array.isArray(this.userType)) return this.userType.includes(this.storeUserType);
        },
        showsContents() {
            return !this.needsLogin || (this.userHasLoggedIn && this.userTypeMatches);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.doesNotMatch) this.sync();
        },
        shows() {
            if (this.doesNotMatch) this.emit();
        },
    },
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
