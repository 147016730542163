var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog-underline-tile', {
    attrs: {
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          isValid: _vm.isValid
        })];
      }
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v(" 약관 ")];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_vm.isLoaded ? _c('div', [_vm._l(_vm.termsList, function (term, index) {
    return [term.code == 'collect-use' ? _c('div', {
      key: term._id,
      class: index != 0 ? 'pt-16px pt-md-24px' : ''
    }, [_c('card-primary', {
      attrs: {
        "cardInnerSize": "pa-12px pa-md-16px"
      }
    }, [_c('div', {
      staticClass: "mh-120px mh-md-160px overflow-y-auto"
    }, [_c('txt-primary', {
      staticClass: "font-size-14"
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(term.content.replace(/\n/gi, '<br/>'))
      }
    })])], 1)])], 1) : _vm._e()];
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('btn-secondary2', {
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")])], 1), _c('v-col', [_c('btn-primary2', {
    attrs: {
      "x-large": "",
      "block": ""
    },
    on: {
      "click": _vm.agree
    }
  }, [_vm._v("동의")])], 1)], 1)], 1)], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }