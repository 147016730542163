<template>
    <v-radio v-bind="$attrs" :class="radioSize">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-radio>
</template>

<script>
export default {
    props: {
        radioSize: { type: String, default: "v-input--density-compact" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
