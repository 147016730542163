<template>
    <u-dialog-underline-tile v-model="shows" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid }" />
        </template>
        <template #tit> 약관 </template>
        <div v-if="isLoaded">
            <!-- <tit-wrap-small>
                <div class="vw-100 w-sm-auto">
                    <v-checkbox v-model="isValid" label="I fully agree to the terms and conditions." readonly hide-details class="v-size--large" @click="toggleSelectedAll" />
                </div>
            </tit-wrap-small> -->
            <!-- <v-divider class="mt-8px" /> -->

            <template v-for="(term, index) in termsList">
                <div v-if="term.code == 'collect-use'" :key="term._id" :class="index != 0 ? 'pt-16px pt-md-24px' : ''">
                    <card-primary cardInnerSize="pa-12px pa-md-16px">
                        <div class="mh-120px mh-md-160px overflow-y-auto">
                            <txt-primary class="font-size-14">
                                <div v-html="term.content.replace(/\n/gi, '<br/>')" />
                            </txt-primary>
                        </div>
                    </card-primary>
                </div>
            </template>

            <div class="btn-wrap">
                <v-row class="row--xs">
                    <v-col>
                        <btn-secondary2 x-large block @click="cancel">취소</btn-secondary2>
                    </v-col>
                    <v-col>
                        <btn-primary2 x-large block @click="agree">동의</btn-primary2>
                    </v-col>
                </v-row>
            </div>
        </div>
    </u-dialog-underline-tile>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import UDialog from "@/components/dumb/u-dialog.vue";
import UDialogUnderlineTile from "@/components/publish/styles/dialogs/u-dialog-underline-tile.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        UDialog,
        UDialogUnderlineTile,
        PageSection,

        DialogPrimary,
        BtnPrimary2,
        BtnSecondary2,
        CardPrimary,
        TxtPrimary,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        attrs_input,
    }),
    computed: {
        isValid() {
            return !this.termsList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                let { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;

                this.isLoaded = true;
            } catch (error) {
                this.$handleError(error);
            }
        },

        toggleSelectedAll() {
            if (this.isValid) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.selected = this.termsList.map(({ _id }) => _id);
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
