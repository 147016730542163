<template>
<!-- 240129 체크박스 Array용 생성 -->
    <v-checkbox v-model="modelValue" v-bind="{ ...attrs_controls_common, ...$attrs }" :value="value" :inputValue="inputValue" :class="checkboxSize" @change="updateModelValue">
        <template v-for="(_, slotName) in $slots" :slot="slotName">
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
import { attrs_controls_common } from "@/assets/variables";

export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        inputValue: { type: [String, Boolean, Number, Array] },
        value: { type: [String, Boolean, Number, Array] }, // 인라인 value prop
        checkboxSize: { type: String, default: "v-input--density-compact" },
    },
    data() {
        return {
            attrs_controls_common,

            modelValue: Array.isArray(this.$props.inputValue) ? [...this.$props.inputValue] : [],
        };
    },
    watch: {
        inputValue() {
            this.modelValue = Array.isArray(this.$props.inputValue) ? [...this.$props.inputValue] : [];
        },
    },
    methods: {
        updateModelValue() {
            this.$emit("change", this.modelValue);
        },
    },
};
</script>

<style lang="scss" scoped></style>
